export function register(extend, i18n) {
    // Validates meeting location field
    extend('meeting_location', {
        message: field => i18n.t('meetings.form.hints.meeting_location', [field]).toString(),
        params: ['model'],
        lazy: true,
        computesRequired: true,
        validate: (value, { model }) => {
            if (model?.location?.length) {
                return true;
            }

            const { start_ts, duration, organizer, participants, externals } = model || {};
            return start_ts && duration && organizer?._id && (participants?.length || externals?.length);
        }
    });
}
