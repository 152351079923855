/**
 * Recursively removes all undefined values from the given object
 *
 * @param {object|null} [object={}] the object to clean
 *
 * @returns {object} the object without undefined values
 */
export function clean(object = {}) {
    if (typeof object === 'object' && object !== null && !Array.isArray(object)) {
        Object.keys(object).forEach(k => {
            if (typeof object[k] === 'undefined') {
                delete object[k];
            }

            if (typeof object[k] === 'object') {
                object[k] = clean(object[k]);
            }
        });

        return object;
    }

    return {};
}
